import { toast } from "react-toastify";
import { ModalConfirmacaoFornecedorStyle } from "./style";
import api from "../../../services/api";
import { useAuth } from "../../../providers/Auth";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

export const ModalConfirmacaoDeFornecedor = ({
  state,
  orcamento,
  orcamentos,
  fornecedores,
  buscarMaterialBriefing,
  buscarMaterialProposta,
  materialBriefing,
  valorDeVenda,
  item,
  cliente,
}) => {
  const [adicionar, setAdicionar] = useState(false);
  const [itensVitrine, setItensVitrine] = useState([]);
  const [loading, setLoading] = useState(false);
  const { token, user } = useAuth();
  const { register, getValues } = useForm();

  useEffect(() => {
    if (orcamento) {
      listarItensVitrine();
    }
  }, [orcamento]);

  if (!orcamento) {
    return;
  }

  const fornecedor =
    materialBriefing.tipo == "Produto"
      ? fornecedores.find((element) => element.id == orcamento.fornecedor_id)
      : fornecedores.find(
          (element) => element.id == orcamento.fornecedor.fornecedor_id
        );

  function enviarEmailFornecedores() {
    api
      .post(`/disputa-fornecedores`, {
        fornecedor: fornecedor,
        fornecedores: fornecedores,
        materialBriefing: materialBriefing,
      })
      .then((response) => {})
      .catch((error) => {
        console.error("Enviar Email Fornecedores na disputa Erro:", error);
        toast.error("Erro ao enviar email para fornecedores. Tente novamente.");
      });
  }

  function emailMaterialVitrineAdicionado(id) {
    const body = {
      produtor_nome: user.nome,
      material: materialBriefing.nome + " " + materialBriefing.subnome,
      quantidade: orcamento.quantidade,
      valor: (Number(valorDeVenda) / Number(orcamento.quantidade)).toFixed(2),
      item: materialBriefing.nome,
      idMaterialVitrine: id,
    };

    api
      .post(`/email/material-vitrine-adicionado`, body)
      .then((response) => {})
      .catch((error) => {
        console.error("Email Adicionar Material Na Vitrine Erro:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  const adicionarHistorico = () => {
    const data = {
      tipo: "Briefing",
      texto: `${user.permissoes.includes("admin") ? "Compras" : "O Produtor"} ${
        user.nome
      } aprovou o orcamento do fornecedor ${
        orcamento.fornecedor_nome
      } para o material ${materialBriefing.nome} quantidade ${
        orcamento.quantidade
      }`,
      campanha_id: materialBriefing.campanhas_id,
    };

    api
      .post("/historico-campanha", [data])
      .then((response) => {})
      .catch((error) => {
        toast.error("Erro ao salvar no historico");
      });
  };

  function listarItensVitrine() {
    api
      .get("/item-vitrine")
      .then((response) => {
        setItensVitrine(response.data.data.itens);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  async function aceitarProposta() {
    if (!item) {
      return toast.error("Item não encontrado");
    }

    if (getValues("item_vitrine") == "") {
      return toast.warning("Selecione um item da vitrine");
    }

    const itemVitrineId = Number(getValues("item_vitrine"));

    const data = {
      campanha_nome: materialBriefing.campanha_nome,
      cliente_id: materialBriefing.criador_id,
      material: materialBriefing,
      texto: "Passar texto aqui",
      item: item,
    };

    adicionarHistorico();

    api
      .post(`/email/disputa-finalizada`, data)
      .then((response) => {})
      .catch((error) => {
        console.error("Aceitar Proposta na disputa Erro:", error);
        toast.error("Erro ao buscar dados");
      });

    const verificarExistencia = await api
      .get(`/material-vitrine/item/${itemVitrineId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response.data.data.material;
      })
      .catch((error) => {});

    const valorNet =
      cliente.preco_composto === 1
        ? valorDeVenda * (1 - orcamento.icms / 100)
        : valorDeVenda;
    const adicionar = getValues().adicionar;

    const propostasRecusadas = orcamentos.filter(
      (elem) => elem.id != orcamento.id
    );
    const idsPropostaRecusadas = propostasRecusadas.map((elem) => elem.id);
    setLoading(true);
    for (let i = 0; i < idsPropostaRecusadas.length; i++) {
      api
        .patch(
          `/orcamento-materiais-briefing/${idsPropostaRecusadas[i]}`,
          { status: "recusado" },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // enviarEmailFornecedores();
        })
        .catch((error) => {
          toast.error("Algo deu Errado. Tente novamente.");
        });
    }
    api
      .patch(
        `/campanha-material-briefing/${materialBriefing.id}`,
        { status: "aguardando análise" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {})
      .catch((error) => {
        toast.error("Algo deu Errado. Tente novamente.");
      });
    api
      .patch(
        `/orcamento-materiais-briefing/${orcamento.id}`,
        { status: "aprovado", valor_de_venda: valorDeVenda },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("Orçamento selecionado! 🎉");
        // enviarEmailFornecedores();
        buscarMaterialBriefing();
        buscarMaterialProposta();
        setLoading(false);
        state(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Algo deu Errado. Tente novamente.");
      });
    if (adicionar) {
      if (!verificarExistencia) {
        api
          .post(
            `/material-vitrine`,
            {
              materiais: [
                {
                  nome: materialBriefing.nome,
                  categoria: item.categoria_item_id,
                  ncm_id: item.ncm_id,
                  observacao: "0",
                  item_vitrine_id: itemVitrineId,
                },
              ],
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            api
              .post(
                `/material-vitrine-valores`,
                {
                  valores: [
                    {
                      preco_unitario:
                        Number(valorNet) / Number(orcamento.quantidade),
                      quantidade_minima: orcamento.quantidade,
                      prazo_entrega: item.ncm_id,
                      material_vitrine: response.data.data.insertId,
                      fornecedor_id: fornecedor.id,
                      orcamento_id: orcamento.id,
                    },
                  ],
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((response) => {
                buscarMaterialBriefing();
                buscarMaterialProposta();
                emailMaterialVitrineAdicionado(response.data.data.insertId);
                state(false);
                setLoading(false);
              })
              .catch((error) => {
                toast.error("Algo deu Errado. Tente novamente.");
                setLoading(false);
              });
          })
          .catch((error) => {
            toast.error("Algo deu Errado. Tente novamente.");
            setLoading(false);
          });
      } else {
        api
          .post(
            `/material-vitrine-valores`,
            {
              valores: [
                {
                  preco_unitario:
                    Number(valorNet) / Number(orcamento.quantidade),
                  quantidade_minima: orcamento.quantidade,
                  prazo_entrega: item.ncm_id,
                  material_vitrine: verificarExistencia.id,
                  fornecedor_id: fornecedor.id,
                  orcamento_id: orcamento.id,
                },
              ],
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            buscarMaterialBriefing();
            buscarMaterialProposta();
            state(false);
          })
          .catch((error) => {
            toast.error("Algo deu Errado. Tente novamente.");
          });
      }
    }
  }

  return (
    <ModalConfirmacaoFornecedorStyle>
      <div className="div-body">
        <span onClick={() => state(false)} className="close">
          X
        </span>
        <div>
          <h5>
            Tem certeza que quer fazer liberação do orçamento do fornecedor{" "}
            {fornecedor?.nome}{" "}
          </h5>
          <div className="flex margin-top">
            <input
              className="input-check"
              type="checkbox"
              {...register("adicionar")}
              onChange={() => setAdicionar(!adicionar)}
            />
            <label>Incluir item na Vitrine</label>
          </div>
        </div>
        {adicionar && itensVitrine && (
          <select {...register("item_vitrine")}>
            <option value="">Selecione um item da vitrine</option>
            {itensVitrine.map((elem) => (
              <option value={elem.id}>{elem.nome}</option>
            ))}
          </select>
        )}
        <button className="salvar" onClick={() => aceitarProposta()}>
          Salvar
        </button>
      </div>
    </ModalConfirmacaoFornecedorStyle>
  );
};
