import { styled } from "styled-components";

export const NovoClienteContainer = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 60px;

  .box {
    width: 90%;
    min-height: 500px;
    background-color: var(--base);
    border-radius: 18px;

    .flex {
      align-items: center;
      margin-bottom: 40px;
    }

    h1 {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 5px;
      margin-left: 10px;
      color: var(--principal);
    }

    h2 {
      font-weight: 600;
      color: var(--principal);
    }

    .h2-header {
      font-size: 14px;
      font-weight: 500;
      text-decoration: underline;
      color: var(--principal);
      margin-left: 11px;
      cursor: pointer;
    }

    .voltar {
      svg {
        width: 40px;
        height: 40px;
      }
    }

    .buttons {
      margin-top: 50px;
      display: flex;
      align-items: center;
      gap: 20px;
      a {
        font-size: 0.9rem;
      }
      button {
        background-color: var(--principal);
        color: var(--base01);
        border: none;
        padding: 5px;
        border-radius: 20px;
        width: 10%;
        font-size: 0.8rem;
      }
    }
  }

  .relative {
    position: relative;
    margin-right: 20px;

    span {
      position: absolute;
      top: -10px;
      right: -8px;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .dadosClientes {
    border-top: 1px solid var(--gray70);
  }

  .opcoes {
    display: flex;
    justify-content: space-between;
    width: 130px;

    div {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;

  .checkbox input {
    display: none;
  }

  .checkbox input + label:before {
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 2px;
    border: 1px solid #979696;
    vertical-align: middle;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
  }

  .checkbox input:checked + label:before {
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 10 10'%3E%3Cg class='nc-icon-wrapper' stroke-width='1' fill='%23555555'%3E%3Cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' data-cap='butt' d='M2.83 4.72l1.58 1.58 2.83-2.83'/%3E%3C/g%3E%3C/svg%3E");
    background-color: var(--principal);
  }

  .checkbox label {
    font-size: 0.9rem;
  }

  h2 {
    margin: 20px 0px 30px 0px;
  }

  > label {
    margin-bottom: 10px;
    font-size: 0.8rem;
  }

  > input {
    border: none;
    background-color: var(--grayF3);
    padding: 10px;
    border-radius: 6px;
    font-size: 0.8rem;
    outline: none;
  }
`;

export const InputsCliente = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0 20px 0;
  gap: 20px;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    > label {
      margin-bottom: 10px;
      font-size: 0.8rem;
    }

    > input,
    select {
      border: none;
      background-color: var(--grayF3);
      padding: 10px;
      border-radius: 6px;
      font-size: 0.8rem;
      outline: none;
    }
  }
`;
export const InputsIncricao = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, 2fr);
  width: 100%;
  margin: 20px 0 0px 0;
  gap: 20px;
  padding-bottom: 15px;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    > label {
      margin-bottom: 10px;
      font-size: 0.8rem;
    }

    .cnae {
      display: flex;
      > input {
        width: 100%;
        border: none;
        background-color: var(--grayF3);
        padding: 10px;
        border-radius: 6px;
        font-size: 0.8rem;
        outline: none;
      }

      button {
        border: none;
        background-color: #cecfcf;
        color: var(--gray70);
        border-radius: 6px;
        font-weight: 600;
        font-size: 0.8rem;
        padding: 10px;
        margin-left: -15px;
      }
    }

    > input,
    select {
      border: none;
      background-color: var(--grayF3);
      padding: 10px;
      border-radius: 6px;
      font-size: 0.8rem;
      outline: none;
    }
  }

  .div-select {
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 10px;
      font-size: 0.8rem;
      width: 100%;
    }
  }
`;

export const InputsEndereco = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0 20px 0;
  gap: 20px;

  .cep {
    width: 65%;
  }
  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    > label {
      margin-bottom: 10px;
      font-size: 0.8rem;
    }

    .cep {
      width: 393px;
    }

    > input,
    select {
      border: none;
      background-color: var(--grayF3);
      padding: 10px;
      border-radius: 6px;
      font-size: 0.8rem;
      outline: none;
    }
  }
`;

export const InputsEndereco1 = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0 20px 0;
  gap: 20px;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    > label {
      margin-bottom: 10px;
      font-size: 0.8rem;
    }

    > input,
    select {
      border: none;
      background-color: var(--grayF3);
      padding: 10px;
      border-radius: 6px;
      font-size: 0.8rem;
      outline: none;
    }
  }
`;

export const InputsEndereco2 = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0 20px 0;
  gap: 20px;
  padding-bottom: 50px;
  border-bottom: 1px solid var(--gray70);

  .email {
    width: 65%;
  }
  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    > label {
      margin-bottom: 10px;
      font-size: 0.8rem;
    }

    > input,
    select {
      border: none;
      background-color: var(--grayF3);
      padding: 10px;
      border-radius: 6px;
      font-size: 0.8rem;
      outline: none;
    }
  }
`;

export const InputsPagamento = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0 20px 0;
  gap: 20px;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    > label {
      margin-bottom: 10px;
      font-size: 0.8rem;
    }

    .cnae {
      display: flex;
      > input {
        width: 100%;
        border: none;
        background-color: var(--grayF3);
        padding: 10px;
        border-radius: 6px;
        font-size: 0.8rem;
        outline: none;
      }

      button {
        border: none;
        background-color: #cecfcf;
        color: var(--gray70);
        border-radius: 6px;
        font-weight: 600;
        font-size: 0.8rem;
        padding: 10px;
        margin-left: -15px;
      }
    }

    > input,
    select {
      border: none;
      background-color: var(--grayF3);
      padding: 10px;
      border-radius: 6px;
      font-size: 0.8rem;
      outline: none;
    }
  }
`;

export const InputsPagamento1 = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0 20px 0;
  gap: 20px;
  padding-bottom: 50px;
  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    > label {
      margin-bottom: 10px;
      font-size: 0.8rem;
    }

    .cnae {
      display: flex;
      > input {
        width: 100%;
        border: none;
        background-color: var(--grayF3);
        padding: 10px;
        border-radius: 6px;
        font-size: 0.8rem;
        outline: none;
      }

      button {
        border: none;
        background-color: #cecfcf;
        color: var(--gray70);
        border-radius: 6px;
        font-weight: 600;
        font-size: 0.8rem;
        padding: 10px;
        margin-left: -15px;
      }
    }

    > input,
    select {
      border: none;
      background-color: var(--grayF3);
      padding: 10px;
      border-radius: 6px;
      font-size: 0.8rem;
      outline: none;
    }
  }
`;

export const InputsPagamento2 = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0 20px 0;
  gap: 20px;
  padding-bottom: 30px;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    > label {
      margin-bottom: 10px;
      font-size: 0.8rem;
    }

    .cnae {
      display: flex;
      > input {
        width: 100%;
        border: none;
        background-color: var(--grayF3);
        padding: 10px;
        border-radius: 6px;
        font-size: 0.8rem;
        outline: none;
      }

      button {
        border: none;
        background-color: #cecfcf;
        color: var(--gray70);
        border-radius: 6px;
        font-weight: 600;
        font-size: 0.8rem;
        padding: 10px;
        margin-left: -15px;
      }
    }

    > input,
    select {
      border: none;
      background-color: var(--grayF3);
      padding: 10px;
      border-radius: 6px;
      font-size: 0.8rem;
      outline: none;
    }
  }
`;
