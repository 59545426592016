import { useNavigate, useParams } from "react-router-dom";
import {
  Body,
  InputsCliente,
  InputsEndereco,
  InputsEndereco1,
  InputsEndereco2,
  InputsIncricao,
  InputsPagamento,
  InputsPagamento1,
  NovoClienteContainer,
  Responsaveis,
} from "./styles";
import Select from "react-select";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import voltar from "../../../../assets/voltar.svg";
import { InputsPagamento2 } from "../../Novo/NovoClienteForm/style";
import Loading from "../../../Loading";

const EditarCliente = () => {
  const { id } = useParams();
  const { user } = useParams();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    reset,
    formState: { errors },
  } = useForm({});

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [marcas, setMarcas] = useState([]);
  const [marcasCliente, setMarcasClientes] = useState([]);
  const [clienteId, setClienteId] = useState([]);

  const [produtoresSelecionados, setProdutoresSelecionados] = useState([]);
  const [atendimentoSelecionados, setAtendimentoSelecionados] = useState([]);
  const [responsaveis, setResponsaveis] = useState({
    produtores: [],
    atendimentos: [],
    produtoresNaoAtribuidos: [],
    atendimentosNaoAtribuidos: [],
  });

  const onSubmitFunction = () => {
    if (loading) {
      return;
    }

    setLoading(true);

    const formValues = getValues();

    const dataToSend = {
      ...formValues,
      nome_registro: "",
      contato_secundario: "",
      telefone_secundario: "",
      telefone_principal: "",
      email_secundario: "",
      link: "",
      empresa_id: 1,
      categorias_cliente_id: 1,
    };

    api
      .patch(`/clientes/${id}`, dataToSend)
      .then((response) => {
        navigate("/clientes");
        setLoading(false);
        toast.success("Cliente atualizado com sucesso!");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });

    if (produtoresSelecionados.length > 0) {
      for (const produtor of produtoresSelecionados) {
        const usuario_cliente = {
          cliente_id: id,
          usuario_id: produtor.value,
          status: 1,
        };
        api
          .post("/usuario-cliente", [usuario_cliente])
          .then((response) => {})
          .catch((error) => {
            return toast.error(error.response.data.message);
          });
      }
    }

    if (atendimentoSelecionados.length > 0) {
      for (const atendimento of atendimentoSelecionados) {
        const usuario_cliente = {
          cliente_id: id,
          usuario_id: atendimento.value,
          status: 1,
        };
        api
          .post("/usuario-cliente", [usuario_cliente])
          .then((response) => {})
          .catch((error) => {
            return toast.error(error.response.data.message);
          });
      }
    }
  };

  function listarMarcas() {
    api
      .get("/marcas")
      .then((response) => {
        setMarcas(response.data.data.marcas);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarClientesResponsaveis() {
    api
      .get(`clientes/cliente/responsaveis/?perfil=cliente`, {
        params: {
          cliente: id,
        },
      })
      .then((response) => {
        setResponsaveis(response.data.data.clientes);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function deletarResponsavel(responsavel_id) {
    if (loading) {
      return;
    }

    setLoading(true);
    api
      .delete(`clientes/cliente/responsaveis/${responsavel_id}`)
      .then((response) => {
        toast.success("Responsável deletado com sucesso!");
        buscarClientesResponsaveis();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  function adicionarMarca(element) {
    const body = {
      marca_id: element,
      cliente_id: id,
    };

    if (!element) {
      return;
    }

    api
      .post(`/marcas-cliente/`, { vinculos: [body] })
      .then(() => {
        api
          .get(`/clientes/${id}`)
          .then((response) => {
            reset(response.data.data.cliente);
          })
          .catch((error) => {
            console.error("Erro ao buscar dados da API:", error);
            toast.error("Erro ao buscar dados");
          });
        reset({ marcas_id: "" });
        listarMarcasCadastradasCliente(id);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function removerMarca(marca_id) {
    api
      .delete(`/marcas-cliente/${marca_id}`, { data: { cliente_id: id } })
      .then(() => {
        listarMarcasCadastradasCliente(id);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function listarMarcasCadastradasCliente(id) {
    api
      .get(`/marcas-cliente/${id}`)
      .then((response) => {
        setMarcasClientes(response.data.data.vinculos);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
      });
  }

  function buscarCliente() {
    setLoading(true);

    api
      .get(`/clientes/${id}`)
      .then((response) => {
        console.log(response.data.data.cliente);
        reset(response.data.data.cliente);
        console.log(getValues("preco_composto"));
        setLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da API:", error);
        toast.error("Erro ao buscar dados");
        setLoading(false);
      });
    listarMarcas();
    listarMarcasCadastradasCliente(id);
    buscarClientesResponsaveis();
  }

  useEffect(() => {
    buscarCliente();
  }, []);

  const moedas = [
    { id: 1, nome: "Real Brasileiro", valor: "BRL" },
    { id: 2, nome: "Dólar Americano", valor: "USD" },
    { id: 3, nome: "Euro", valor: "EUR" },
    { id: 4, nome: "Libra Esterlina (Reino Unido)", valor: "GBP" },
    { id: 5, nome: "Iene Japonês", valor: "JPY" },
    { id: 6, nome: "Dólar Australiano", valor: "AUD" },
    { id: 7, nome: "Dólar Canadense", valor: "CAD" },
    { id: 8, nome: "Yuan Chinês", valor: "CNY" },
    { id: 9, nome: "Rúpia Indiana", valor: "INR" },
    { id: 10, nome: "Rublo Russo", valor: "RUB" },
  ];

  const checkCEP = (e) => {
    const codigo_postal = e.target.value.replace(/\D/g, "");

    fetch(`https://viacep.com.br/ws/${codigo_postal}/json/`)
      .then((res) => res.json())
      .then((data) => {
        setValue("logradouro", data.logradouro);
        setValue("bairro", data.bairro);
        setValue("cidade", data.localidade);
        setValue("estado", data.uf);
        setFocus("number");
      })
      .catch((error) => {
        toast.error("CEP inválido");
      });
  };

  const newArray = marcas.filter((item1) => {
    return !marcasCliente.some((item2) => item1.id === item2.marca_id);
  });

  return (
    <NovoClienteContainer onSubmit={handleSubmit(onSubmitFunction)}>
      <div className="box">
        <div className="flex">
          <ReactSVG
            onClick={() => navigate("/clientes")}
            className="voltar"
            src={voltar}
          />
          <div className="column">
            <h1>Cliente</h1>
            <h2 className="h2-header">Editar Cliente</h2>
          </div>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Body>
              <div className="checkbox">
                <input
                  type="checkbox"
                  id="checkbox1"
                  name="status"
                  {...register("status")}
                  defaultChecked={true}
                />
                <label htmlFor="checkbox1">Ativo</label>
              </div>
              <InputsCliente>
                <div className="cnpj">
                  <label>CNPJ</label>
                  <input
                    type="text"
                    name="documento"
                    {...register("documento")}
                    placeholder="00.000.000/0000-00"
                    required
                  />
                </div>
                <div>
                  <label>Razão Social</label>
                  <input
                    type="text"
                    name="razao_social"
                    {...register("razao_social")}
                    placeholder="Razão Social do cliente"
                    required
                  />
                </div>
                <div>
                  <label>Nome Fantasia</label>
                  <input
                    type="text"
                    name="nome_fantasia"
                    {...register("nome_fantasia")}
                    placeholder="Nome Fantasia do cliente"
                    required
                  />
                </div>
                <div>
                  <label>Tipo</label>
                  <select name="tipo" {...register("tipo")}>
                    <option key={0} value={0}>
                      Selecionar tipo
                    </option>
                    <option value="Qualquer">Matrix</option>
                    <option value="Qualquer">Filial</option>
                  </select>

                  {errors.tipo && (
                    <span className="input-error-cadastro">
                      {errors.tipo.message}
                    </span>
                  )}
                </div>
              </InputsCliente>
              <InputsIncricao>
                <div>
                  <label>Inscrição Municipal</label>
                  <input
                    type="text"
                    name="inscricao_municipal"
                    {...register("inscricao_municipal")}
                    placeholder="0000000000000"
                  />
                  {errors.inscricao_municipal && (
                    <span className="input-error-cadastro">
                      {errors.inscricao_municipal.message}
                    </span>
                  )}
                </div>
                <div>
                  <label>Inscrição Estadual *</label>
                  <input
                    type="text"
                    name="inscricao_estadual"
                    {...register("inscricao_estadual")}
                    placeholder="0000000000000"
                  />
                  {errors.documento && (
                    <span className="input-error-cadastro">
                      {errors.inscricao_estadual.message}
                    </span>
                  )}
                </div>
                <div>
                  <label>CNAE Principal</label>
                  <input
                    type="file"
                    name="cnae"
                    placeholder="tested"
                    {...register("cnae")}
                  />
                  {errors.cnae && (
                    <span className="input-error-cadastro">
                      {errors.cnae.message}
                    </span>
                  )}
                </div>
                <div>
                  <label>Registro Fiscal</label>
                  <input
                    type="text"
                    name="registro_fiscal"
                    {...register("registro_fiscal")}
                    placeholder="0000000000000"
                  />
                </div>
                <div className="flex-input">
                  <div className="div-select">
                    <label>Marcas</label>
                    <select
                      onClick={(e) => adicionarMarca(e.target.value)}
                      {...register("marcas_id")}
                    >
                      <option value="">Selecionar a marca</option>
                      {newArray &&
                        newArray.map((element) => (
                          <option value={element.id}>{element.nome}</option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="flex-input">
                  <div className="div-select">
                    <label>Preço composto *</label>
                    <div className="opcoes">
                      <div>
                        <span>Sim</span>
                        <input
                          type="radio"
                          id="preco_composto"
                          name="preco_composto"
                          value={1}
                          defaultChecked={getValues("preco_composto") === 1}
                          {...register("preco_composto")}
                        />
                      </div>
                      <div>
                        <span>Não</span>
                        <input
                          type="radio"
                          id="preco_composto"
                          name="preco_composto"
                          value={0}
                          defaultChecked={getValues("preco_composto") === 0}
                          {...register("preco_composto")}
                        />
                      </div>
                    </div>
                    {errors.preco_composto && (
                      <span className="input-error-cadastro">
                        {errors.preco_composto.message}
                      </span>
                    )}
                  </div>
                </div>
              </InputsIncricao>
              <div className="flex">
                {marcasCliente.length > 0 &&
                  marcas.length > 0 &&
                  marcasCliente.map((elemento) => (
                    <div className="relative">
                      <p className="nome-marca">
                        {
                          marcas.find((elem) => elem.id === elemento.marca_id)
                            .nome
                        }
                      </p>
                      <span onClick={() => removerMarca(elemento.id)}>X</span>
                    </div>
                  ))}
              </div>
              <div className="dadosClientes">
                <h2> Dados do cliente</h2>
                <InputsEndereco>
                  <div className="cep">
                    <label>CEP *</label>
                    <input
                      type="text"
                      name="codigo_postal"
                      {...register("codigo_postal")}
                      placeholder="00000-000"
                      onBlur={(e) => checkCEP(e)}
                    />
                    {errors.codigo_postal && (
                      <span className="input-error-cadastro">
                        {errors.codigo_postal.message}
                      </span>
                    )}
                  </div>
                  <div>
                    <label>Logradouro *</label>
                    <input
                      type="text"
                      name="logradouro"
                      {...register("logradouro")}
                      placeholder="Avenida, rua, travessa"
                    />
                    {errors.logradouro && (
                      <span className="input-error-cadastro">
                        {errors.logradouro.message}
                      </span>
                    )}
                  </div>
                  <div>
                    <label>Número *</label>
                    <input
                      type="text"
                      name="numero"
                      {...register("numero")}
                      placeholder="0000"
                    />
                    {errors.numero && (
                      <span className="input-error-cadastro">
                        {errors.numero.message}
                      </span>
                    )}
                  </div>
                </InputsEndereco>
                <InputsEndereco1>
                  <div>
                    <label>Complemento</label>
                    <input
                      type="text"
                      name="complemento"
                      {...register("complemento")}
                      placeholder="Sala, andar"
                    />
                  </div>
                  <div>
                    <label>Bairro *</label>
                    <input
                      type="text"
                      name="bairro"
                      {...register("bairro")}
                      placeholder="Bairro"
                    />
                    {errors.bairro && (
                      <span className="input-error-cadastro">
                        {errors.bairro.message}
                      </span>
                    )}
                  </div>
                  <div>
                    <label>Cidade *</label>
                    <input
                      type="text"
                      name="cidade"
                      {...register("cidade")}
                      placeholder="Cidade"
                    />
                    {errors.cidade && (
                      <span className="input-error-cadastro">
                        {errors.cidade.message}
                      </span>
                    )}
                  </div>
                  <div>
                    <label>Estado *</label>
                    <input
                      type="text"
                      name="estado"
                      {...register("estado")}
                      placeholder="UF"
                    />
                    {errors.estado && (
                      <span className="input-error-cadastro">
                        {errors.estado.message}
                      </span>
                    )}
                  </div>
                </InputsEndereco1>
                <InputsEndereco2>
                  <div className="email">
                    <label>E-mail contato *</label>
                    <input
                      type="email"
                      name="email_principal"
                      {...register("email_principal")}
                      placeholder="email@email.com.br"
                    />
                    {errors.email_principal && (
                      <span className="input-error-cadastro">
                        {errors.email_principal.message}
                      </span>
                    )}
                  </div>
                  <div>
                    <label>Celular contato *</label>
                    <input
                      type="text"
                      name="contato_principal"
                      {...register("contato_principal")}
                      placeholder="(00) 00000-0000"
                    />
                    {errors.contato_principal && (
                      <span className="input-error-cadastro">
                        {errors.contato_principal.message}
                      </span>
                    )}
                  </div>
                  <div>
                    <label>Site do cliente</label>
                    <input
                      type="text"
                      name="link"
                      {...register("link")}
                      placeholder="www.cliente.com.br"
                    />
                    {errors.contato_principal && (
                      <span className="input-error-cadastro">
                        {errors.contato_principal.message}
                      </span>
                    )}
                  </div>
                  <div>
                    <label>Setor</label>
                    <input
                      type="text"
                      name="setor_atuacao"
                      {...register("setor_atuacao")}
                      placeholder="Setor de atuação do cliente"
                    />
                  </div>
                </InputsEndereco2>
              </div>
              <div className="dadosPagamento">
                <h2> Dados de Pagamento</h2>
                <InputsPagamento>
                  <div>
                    <label>Nome do responsável financeiro *</label>
                    <input
                      type="text"
                      name="contato_financeiro"
                      {...register("contato_financeiro")}
                      placeholder="Responsável financeiro"
                    />
                    {errors.contato_financeiro && (
                      <span className="input-error-cadastro">
                        {errors.contato_financeiro.message}
                      </span>
                    )}
                  </div>
                  <div>
                    <label>E-mail financeiro *</label>
                    <input
                      type="email"
                      name="email_financeiro"
                      {...register("email_financeiro")}
                      placeholder="E-mail financeiro"
                    />
                    {errors.email_financeiro && (
                      <span className="input-error-cadastro">
                        {errors.email_financeiro.message}
                      </span>
                    )}
                  </div>
                </InputsPagamento>
                <InputsPagamento2>
                  <div>
                    <label>Banco</label>
                    <select name="banco" {...register("banco")}>
                      <option>Selecione o banco</option>
                      <option value={1}>Banco do Brasil</option>
                      <option value={2}>Caixa</option>
                      <option value={3}>Citibank</option>
                      <option value={4}>Inter</option>
                      <option value={5}>Itaú</option>
                      <option value={6}>Original</option>
                      <option value={7}>Safra</option>
                      <option value={8}>Santander</option>
                      <option value={9}>ABC</option>
                      <option value={10}>Votorantim</option>
                      <option value={11}>Sicoob</option>
                      <option value={12}>Sicredi</option>
                      <option value={13}>C6</option>
                      <option value={14}>BTG Pactual</option>
                      <option value={15}>Banrisul</option>
                      <option value={16}>Banco do Nordeste</option>
                      <option value={17}>Outro</option>
                    </select>
                  </div>
                  <div>
                    <label>Agência</label>
                    <input
                      type="text"
                      name="agencia"
                      {...register("agencia")}
                      placeholder="00000"
                    />
                  </div>
                  <div>
                    <label>Conta</label>
                    <input
                      type="text"
                      name="Conta"
                      {...register("conta")}
                      placeholder="0000000"
                    />
                  </div>
                </InputsPagamento2>
                <InputsPagamento1>
                  <div>
                    <label>Prazo de Pagamento *</label>
                    <input
                      type="number"
                      name="prazo_pagamento"
                      {...register("prazo_pagamento")}
                      placeholder="Em Dias"
                    />
                    {errors.prazo_pagamento && (
                      <span className="input-error-cadastro">
                        {errors.prazo_pagamento.message}
                      </span>
                    )}
                  </div>
                  <div>
                    <label>Moeda de transação *</label>
                    <select
                      name="moeda_transacao"
                      {...register("moeda_transacao")}
                    >
                      <option key={0} value={0}>
                        Selecione uma moeda
                      </option>
                      {moedas.map((item) => (
                        <option key={item.id} value={item.valor}>
                          {item.nome}
                        </option>
                      ))}
                    </select>
                    {errors.moeda_transacao && (
                      <span className="input-error-cadastro">
                        {errors.moeda_transacao.message}
                      </span>
                    )}
                  </div>
                  <div>
                    <label>Fee contratual %*</label>
                    <input
                      type="float"
                      name="prazo_pagamento"
                      {...register("fee")}
                      placeholder="0"
                    />
                    {errors.fee && (
                      <span className="input-error-cadastro">
                        {errors.fee.message}
                      </span>
                    )}
                  </div>
                </InputsPagamento1>
              </div>
              <>
                <Responsaveis>
                  <div className="select">
                    <h2>Produtores Não Atribuídos:</h2>
                    <Select
                      isMulti={true}
                      value={produtoresSelecionados}
                      onChange={setProdutoresSelecionados}
                      options={responsaveis.produtoresNaoAtribuidos}
                    />
                  </div>
                  <div>
                    <h4 className="responsavel">Produtores Cadastrados:</h4>
                    <div className="flex">
                      {responsaveis.produtores &&
                        responsaveis.produtores.map((responsavel) => (
                          <div className="item" key={responsavel.id}>
                            <span>{responsavel.nome}</span>
                            <span
                              className="close"
                              onClick={() => deletarResponsavel(responsavel.id)}
                            >
                              X
                            </span>
                          </div>
                        ))}
                    </div>
                  </div>
                </Responsaveis>
                <Responsaveis>
                  <div className="select">
                    <h2>Atendimento Não Atribuídos:</h2>
                    <Select
                      onChange={setAtendimentoSelecionados}
                      options={responsaveis.atendimentoNaoAtribuidos}
                      isMulti={true}
                    />
                  </div>
                  <div>
                    <h4 className="responsavel">Atendimento Cadastrados:</h4>
                    <div className="flex">
                      {responsaveis.atendimentos &&
                        responsaveis.atendimentos.map((responsavel) => (
                          <div className="item" key={responsavel.id}>
                            <span>{responsavel.nome}</span>
                            <span
                              className="close"
                              onClick={() => deletarResponsavel(responsavel.id)}
                            >
                              X
                            </span>
                          </div>
                        ))}
                    </div>
                  </div>
                </Responsaveis>
              </>
            </Body>
            <div className="buttons">
              <button type="submit">Salvar</button>
            </div>
          </>
        )}
      </div>
    </NovoClienteContainer>
  );
};

export default EditarCliente;
